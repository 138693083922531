

.warning_block{
    margin-bottom: 30px;
    display: flex;
    margin-top: 30px;
}

.warning_block_empty{
    background-color: var(--color-other1);
    width: 30px;
}

.warning_block_empty_red{
    background-color: var(--color-other2);
    width: 30px;
}

.warning_block_message{
    padding: 10px;
    background-color: var(--color-fg-1);
}
