.navbar{
    display: flex;
    
    border-bottom: solid 1px var(--color-fg-1);
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-bg);
    

    height: var(--header-height);
    max-width: var(--page-width);
    top: 0;
    left: 50%;right: -50%;
    transform: translateX(-50%);
    z-index: 2;
    position: fixed;

}

.navbar_leftside{
    color: var(--text-color);
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 30px;
    
    display: flex;
    align-items: center;
    gap: 20px;
    
    padding: 10px;
    cursor: pointer;
}
.navbar_img_dark{
    display: block;
}
.navbar_img_light{
    display: none;
}







.navbar_rightside{
    display: flex;
    gap: 20px;
    margin-right: 30px;
    padding: 10px;
    
}

.navbar img{
    width: calc(100px * 0.8);
    height: calc(60px * 0.8)
}
.navbar li{
    list-style-type: none;
    
}
.navbar a{  
    padding: 10px;
    text-decoration: none;

}


.navbar_rightside_hamburger{
    color: var(--color-fg-3);
    text-align: center;
    font-size: 2rem;
    display: none;

}
.navbar_rightside_hamburger:hover{
    color:var(--text-color);
    cursor: pointer;
}

.navbar_rightside_hamburger_selected{
    color: var(--color-special2);
    text-align: center;
    font-size: 2rem;
    display: none;

    
}

.navbar_rightside_hamburger_selected:hover{
    cursor: pointer;
}

@media (prefers-color-scheme:light) {
    .navbar_img_dark{
        display: none;
    }
    .navbar_img_light{
        display: block;
    }
}

@media screen and (max-width:700px) {
    .navbar{
        left: 0; right: 0;
        transform: translateX(0);
    }
    .navbar_leftside_hidable{
        display: none;
    }
    .navbar_rightside a{
        display: none;
    }
    .navbar_rightside_hamburger{
        display: block;
    }
    .navbar_rightside_hamburger_selected{
        display: block;
    }
}