.indexmodule{
    width: var(--sidebar-width);
    background-color: var(--color-bg);
    display: flex;
    flex-direction: column;

    height: calc(100vh - 65px);
    overflow-y: scroll;
    position: fixed;
    margin-left: 0;
    font-size: 0.9em;
    
}
.indexmodule_short{
    display: block;
    margin-left: 5px;
    margin-top: 60px;
  
}
.indexmodule_short_hidden{
    display: none;
}

.indexmodule_list{
    padding-top: 20px; padding-bottom: 20px;
}
.indexmodule_list ul{
    padding-top: 20px; padding-left: 20px; padding-bottom: 20px;
    font-weight: bold;
    color: var(--text-color);


}

.indexmodule_list ul li{
    margin-left:5px; margin-top: 20px;
    font-weight: normal;

    list-style-type: none;
    color: var( --color-fg-3);
    
    
}

.indexmodule_list a{
    display: flex;
    width: 90%;
    padding-left: 10px;padding-top: 5px;padding-bottom: 5px;
    text-decoration: none;
}

.indexmodule_list ul li:hover{
    color: var(--text-color);
    cursor: pointer;
}

@media screen and (min-width: 1414px) {
    .indexmodule{
        left: 50vw;
        transform: translateX( calc(  -1 * var(--page-width) / 2));
    }
}