


.table_of_content{
    
    
    position: sticky;
    top: calc(var(--header-height) + 30px);

    margin-left: 10px;

}

.table_of_content_title{
    font-size: 1.5rem;
}

.table_of_content_stuff_parent{
    

    text-decoration: none;
    color: var(--color-special2);
}

.table_of_content_stuff_child{
    
    text-decoration: none;

    color: var(--text-color);
}

.table_of_content_stuff_parent_li{
    margin: 20px 10px 10px 20px;
    list-style-type: none;
}
.table_of_content_stuff_child_li{
    padding: 5px;
    padding-left: 30px;
    list-style-type: none;
}


.table_of_content_stuff a:hover{
    text-decoration: underline;
}