

.custom_link_S{

    background-color: var(--color-fg-2);
    
    
    color: var(--color-special2)
}
.custom_link_NS{
    
    color: var(--color-fg-3);
    
}
.custom_link_NS:hover{
    color:var(--text-color);
}




