:root{
    --color-special1:#13a10e;
    --color-special2:#00FF00;
    --color-other1:yellow;
    --color-other2:red;
    --color-bg:#121212;
    --color-fg-1:#383838;
    --color-fg-2:#4a4a4a;
    --color-fg-3:#737373;
    --border-radius:30px;
    --text-color:white;
    color-scheme: dark;
}
:root{
    --header-height:65px;
    --sidebar-width:250px;
    --page-width:1400px;
    --content-width: calc(var(--page-width) - var(--sidebar-width));
}
@media (prefers-color-scheme:light) {
    :root{
        --color-bg:#FFFFFF;
        --color-fg-1:#e3e3e3;
        --color-fg-2:#d0d0d0;
        --color-fg-3:#bbbbbb;
        --text-color:black;

        color-scheme: light;
    }
}


*{
    font-family: 'Lexend', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}



body{
    background-color: var(--color-bg);
    color: var(--text-color);
    

}

.not_fixed{
    
    margin-top: 65px;
    margin-left:calc(calc(100% - var(--page-width)) / 2 + var(--sidebar-width) + 10px);
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: calc(var(--content-width));
}

.not_fixed_short{
    margin-top: 65px;
    margin-left: left;
    height: 100%;
    display: flex;
    flex-direction: column;

}



@media screen and (max-width: 1415px) {
    .not_fixed{
        margin-left: var(--sidebar-width)
    }
}








