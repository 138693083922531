.contentpage{
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}
.contentpage h1{

    margin-bottom: 50px;
}
.contentpage h3{
    margin-bottom: -20px;
    scroll-margin-top: var(--header-height);
    
}
.contentpage h2{
    border-top: 1px solid var(--color-fg-1);
    margin-top: 50px;
    padding-top: 20px;
    scroll-margin-top: var(--header-height);
}

.contentpage h5{
    margin-left: 30px;
    color: var(--color-special1);
}


.contentpage p{
    margin-top: 20px;
    margin-bottom: 30px;
    
    line-height: 2.5;
    color: var(--text-color-alt);
}

.contentpage span{
    color: var(--color-special2);
    font-weight: bold;
}

.contentpage ul{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    color: var(--color-special2);
}

.contentpage ol{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    color: var(--color-special2);
}

.contentpage mathmiddle{
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contentpage img{
    width: 100%;
    background-color: var(--color-fg-1);
    padding: 20px;
    min-width: 250px;
    border-radius: 30px;
    box-shadow: -2px 2px 5px var(--color-fg-1), 2px 2px 5px var(--color-fg-1);
}

.contentpage_phoneimg{
    width: 30%;
}
.contentpage video{
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    background-color: var(--color-fg-1);
    padding: 20px;
    min-width: 250px;
    border-radius: 30px;
    box-shadow: -2px 2px 5px var(--color-fg-1), 2px 2px 5px var(--color-fg-1);
}

.contentpage address{
    text-decoration: underline;
    width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.contentpage li{
    width: 100%;
}

.donate_button img{
    min-width: none;
    background-color: transparent;
    border-style: none;
    border-width: 0px;
    box-shadow: none;
    min-width: 100px;
    max-width: 150px;

}