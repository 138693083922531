.page{
    min-height: 100%;
    margin-left: 30px;
    margin-top: 30px;
    margin-right: 30px;

    display: flex; flex-direction: column;
    min-height: calc(100vh - var(--header-height));


}



.page_horizontal{
    display: flex;
    flex-direction: row;
    
}

.page_content{
    flex-grow: 1;
    max-width: calc(var(--content-width) - 400px);
    
}

.page_toc{
    margin-left: 40px;

}

@media screen and (max-width : 1415px) {
    .page_toc{
        display: none;
    }
}
